import React, {useState} from "react";
import HomeCtaSection from "../home/home-cta-section";
import {useForm} from "react-hook-form";
import {post} from "../../services/BaseService";
import {appUrls} from "../../util/api_constant";

declare let UIkit: any;

const ContactContent = () => {
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<any>({mode: 'all'});
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onSubmit = (data: any) => {
        setIsSubmitting(true);
        post(appUrls.contact, data)
            .then(() => {
                if (UIkit){
                    UIkit.notification({message: `<span uk-icon='icon: check'></span> Félicitation ! <br> Votre message a été envoyé avec succès`,  status: 'success'});
                }
                setValue('fullName', undefined);
                setValue('email', undefined);
                setValue('phoneNumber', undefined);
                setValue('subject', undefined);
                setValue('message', undefined);
                setIsSubmitting(false);
            }).catch(() => {
            setIsSubmitting(false);
            if (UIkit){
                UIkit.notification({message: `<span uk-icon='icon: close'></span> Ooops!. <br> Queque chose s'est mal passé. Vérifiez les données saisies et réessayez ultérieurement !`,  status: 'danger'});
            }
        })
    }

    return <>
        <div className="page-head">
            <div className="page-head__bg" style={{backgroundImage: "url(/assets/img/bg/bg_contacts.jpg)"}}>
                <div className="page-head__content" data-uk-parallax="y: 0, 100">
                    <div className="uk-container">
                        <div className="header-icons"><span></span><span></span><span></span></div>
                        <div className="page-head__title"> Nous contacter</div>
                        <div className="page-head__breadcrumb">
                            <ul className="uk-breadcrumb">
                                <li><a href="#">Home</a></li>
                                <li><span>Nous contacter</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content">
            <div className="uk-section-large uk-container">
                <div className="contacts-block">
                    <div className="uk-grid uk-grid-collapse" data-uk-grid="true">
                        <div className="uk-width-1-3@m">
                            <div className="sidebar">
                                <div className="widjet widjet-contacts">
                                    <h4 className="widjet__title">Contact Details</h4>
                                    <ul className="contacts-list">
                                        <li className="contacts-list-item">
                                            <div className="contacts-list-item__icon"><img
                                                src="/assets/img/icons/ico-contact-1.svg" data-uk-svg="true"
                                                alt="HeadOffice Address" /></div>
                                            <div className="contacts-list-item__desc">
                                                <div className="contacts-list-item__label">Adresse</div>
                                                <div className="contacts-list-item__content">Hamdallaye ACI 2000, Bamako – Mali
                                                </div>
                                            </div>
                                        </li>
                                        <li className="contacts-list-item">
                                            <div className="contacts-list-item__icon"><img
                                                src="/assets/img/icons/ico-contact-2.svg" data-uk-svg="true"
                                                alt="For Rental Support" /></div>
                                            <div className="contacts-list-item__desc">
                                                <div className="contacts-list-item__label">Pour tout besoin</div>
                                                <div className="contacts-list-item__content"><a
                                                    href="tel:0022389890357">(+223) 89 89 03 57 / 92 04 78 40</a></div>
                                            </div>
                                        </li>
                                        <li className="contacts-list-item">
                                            <div className="contacts-list-item__icon"><img
                                                src="/assets/img/icons/ico-contact-3.svg" data-uk-svg="true"
                                                alt="The Office Hours" /></div>
                                            <div className="contacts-list-item__desc">
                                                <div className="contacts-list-item__label">Horaires de travail</div>
                                                <div className="contacts-list-item__content">Lundi - Vendredi, 08h00-18h00</div>
                                            </div>
                                        </li>
                                        <li className="contacts-list-item">
                                            <div className="contacts-list-item__icon"><img
                                                src="/assets/img/icons/ico-contact-4.svg" data-uk-svg="true"
                                                alt="Send Us Email"/></div>
                                            <div className="contacts-list-item__desc">
                                                <div className="contacts-list-item__label">Email</div>
                                                <div className="contacts-list-item__content"><a
                                                    href="mailto:contact@smesarl.com">contact@smesarl.com</a></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-2-3@m">
                            <div className="block-form">
                                <div className="section-title">
                                    <div className="uk-h2">Envoyez-nous un message</div>
                                </div>
                                <div className="section-content">
                                    <p>Nous assurons la confidentialité de vos données. Tous les champs marqués * sont obligatoires.</p>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="uk-grid uk-grid-small uk-child-width-1-2@s" data-uk-grid="true">
                                            <div><input {...register(`fullName`,{required: true})} className={`uk-input uk-form-large ${errors[`fullName`] ? 'uk-form-danger' : ''}`} type="text"
                                                        placeholder="Nom complet *"/></div>
                                            <div><input {...register(`email`,{required: true})} className={`uk-input uk-form-large ${errors[`email`] ? 'uk-form-danger' : ''}`} type="email"
                                                        placeholder="Email *"/></div>
                                            <div className="uk-width-1-2">
                                                <input {...register(`subject`,{required: true})} className={`uk-input uk-form-large ${errors[`subject`] ? 'uk-form-danger' : ''}`}
                                                       type="text" placeholder="Objet *"/>
                                            </div>
                                            <div className="uk-width-1-2">
                                                <input {...register(`phoneNumber`,{required: false})} className={`uk-input uk-form-large ${errors[`phoneNumber`] ? 'uk-form-danger' : ''}`}
                                                       type="text" placeholder="Numéro de téléphone"/>
                                            </div>
                                            <div className="uk-width-1-1">
                                                <textarea
                                                    {...register(`message`,{required: true})}
                                                className={`uk-textarea uk-form-large ${errors[`subject`] ? 'uk-form-danger' : ''}`}
                                                placeholder="Votre Message ici *"></textarea>
                                            </div>
                                            <div>
                                                <button disabled={isSubmitting} className="uk-button uk-button-large" type="submit">
                                                    {isSubmitting?<span><div uk-spinner="ratio: 2"/>Envoi en cours ...</span>:
                                                        <span>Envoyer le message </span>}
                                                    {
                                                        (!isSubmitting)?<img src="/assets/img/icons/arrow.svg" alt="arrow" data-uk-svg="true"/>:null
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contacts-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15572.962457457239!2d-8.033409174712697!3d12.632213945639148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xe51cc5df81b5b11%3A0x572b6d6dbdd05f4!2sACI%202000%2C%20Bamako%2C%20Mali!5e0!3m2!1sen!2sgh!4v1675609030307!5m2!1sen!2sgh"
                        width="600" height="450" style={{border:0}} loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
        <HomeCtaSection/>
    </>
}

export default ContactContent;
