import React, {useEffect} from "react";
import Layout from "../components/layout/Layout";
import ContactContent from "../components/contact/ContactContent";
import {loadScript} from "../util/helper";
const Contact = () => {
    useEffect(() => {
        loadScript("/assets/js/libs.js");
        setTimeout(() => {
            loadScript("/assets/js/main.js");
        })
    })
    return <>
    <Layout title={'Contact'}>
        <ContactContent/>
    </Layout>
    </>
}
export default Contact;